import { CANCER_TYPES, KOR_CANCER_TYPES } from '@/consts/cancer'
import type { CancerType, KorCancerType } from '@/consts/cancer'
import { APPOINTMENT_NOTE_QUERY_STRING, URL_PATH } from '@/consts/urls'

export const GNB_MENUS = [
  {
    menu: '주제별 암 정보',
    isUse: true,
    subMenus: [
      {
        text: KOR_CANCER_TYPES.breast,
        url: URL_PATH.GuidesMainBreast,
        isBeta: false,
        gaSelector: 'menu_guide_breast',
        parentUrl: URL_PATH.GuidesBreast,
      },
      {
        text: KOR_CANCER_TYPES.thyroid,
        url: URL_PATH.GuidesMainThyroid,
        isBeta: false,
        gaSelector: 'menu_guide_thyroid',
        parentUrl: URL_PATH.GuidesThyroid,
      },
      {
        text: KOR_CANCER_TYPES.lung,
        url: URL_PATH.GuidesMainLung,
        isBeta: false,
        gaSelector: 'menu_guide_lung',
        parentUrl: URL_PATH.GuidesLung,
      },
      {
        text: KOR_CANCER_TYPES.stomach,
        url: URL_PATH.GuidesMainStomach,
        isBeta: false,
        gaSelector: 'menu_guide_stomach',
        parentUrl: URL_PATH.GuidesStomach,
      },
      {
        text: KOR_CANCER_TYPES.colon,
        url: URL_PATH.GuidesMainColon,
        isBeta: false,
        gaSelector: 'menu_guide_colon',
        parentUrl: URL_PATH.GuidesColon,
      },
      {
        text: '생활백서',
        url: URL_PATH.GuidesLife,
        isBeta: false,
        gaSelector: 'menu_guide_life',
        parentUrl: '',
      },
    ],
  },
  {
    menu: '',
    isUse: true,
    subMenus: [
      {
        text: '치료비 계산기',
        url: URL_PATH.Calculator,
        isBeta: false,
        gaSelector: 'menu_calculator',
        parentUrl: '',
      },
      {
        text: '병원 정보',
        url: URL_PATH.HospitalsMain,
        isBeta: false,
        gaSelector: 'menu_hospitals',
        parentUrl: '',
      },
      {
        text: 'AI 진료 노트',
        url: `${URL_PATH.GetStarted}${APPOINTMENT_NOTE_QUERY_STRING}`,
        privateUrl: URL_PATH.MyNotes,
        isBeta: true,
        gaSelector: 'menu_notes',
        parentUrl: '',
      },
      {
        text: '항암 정보',
        url: URL_PATH.Regimens,
        privateUrl: URL_PATH.MyRegimens,
        isBeta: true,
        gaSelector: 'menu_regimens',
        parentUrl: '',
      },
      {
        text: '질문과 답변',
        url: URL_PATH.Boards,
        isBeta: false,
        gaSelector: 'menu_boards',
        parentUrl: '',
      },
      {
        text: 'AI 영양사',
        url: URL_PATH.Assistant,
        privateUrl: URL_PATH.MyAssistant,
        isBeta: true,
        gaSelector: 'menu_assistant',
        parentUrl: '',
      },
    ],
  },
  {
    menu: '',
    isUse: true,
    subMenus: [
      {
        text: '루닛케어 소개',
        url: URL_PATH.Service,
        isBeta: false,
        gaSelector: 'menu_service',
        parentUrl: '',
      },
      {
        text: '공지사항',
        url: URL_PATH.Notices,
        isBeta: false,
        gaSelector: 'menu_notices',
        parentUrl: '',
      },
    ],
  },
]

export const TAB_MENUS = Object.entries(KOR_CANCER_TYPES)
  .filter((cancer) => cancer[0] !== CANCER_TYPES.colon)
  .map((cancer) => ({
    id: cancer[0] as CancerType,
    label: cancer[1] as KorCancerType,
  }))
