import { S3_IMAGE_URL } from '@/config'

const TITLES = {
  BOARDS: '질문과 답변',
  GUIDES: '정보',
  HOSPITALS: '병원 정보',
}
const LUNIT_CARE = '루닛케어'

export const DESCRIPTION = {
  MAIN: '암 환자와 보호자를 위한 원스톱 토탈 케어 서비스',
  BOARDS: '암 전문 의료팀이 제공하는 1:1 맞춤 답변',
  GUIDES: '의심,진단,치료,회복 모든 암 치료 여정을 함께 하는 안내서',
  HOSPITALS: '우수한 암 치료 역량을 갖춘 병원에 대한 정보',
}

const META_TITLES: { [key: string]: string } = {
  ASSISTANT: 'AI 영양사',
  MAIN: LUNIT_CARE,
  CONTENTS: '콘텐츠 목록',
  BOARDS_MAIN: TITLES.BOARDS,
  BOARD_VIEW: TITLES.BOARDS,
  BOARD_CREATE: TITLES.BOARDS,
  BOARD_UPDATE: TITLES.BOARDS,
  BOARD_CREATE_COMPLETE: TITLES.BOARDS,
  BOARD_UPDATE_COMPLETE: TITLES.BOARDS,
  BOARD_ANSWER_PROCESS: TITLES.BOARDS,
  BOOKMARKS: '저장함',
  GUIDES_MAIN: TITLES.GUIDES,
  GUIDES_LIST: '목차',
  GUIDE_VIEW: TITLES.GUIDES,
  GUIDE_LIFE_STYLE: '생활백서',
  HOSPITALS_MAIN: TITLES.HOSPITALS,
  HOSPITALS_SEARCH: TITLES.HOSPITALS,
  HOSPITALS_DETAIL: TITLES.HOSPITALS,
  HOSPITALS_APPOINTMENT: '병원 예약 | 병원 정보',
  MYCARE: '마이케어',
  MY_PROFILE: '내 정보 수정',
  MY_NOTICES: '알림',
  MY_NOTES: 'AI 진료 노트',
  MYCARE_BOARDS: '질문함',
  MYCARE_BOARD_VIEW: TITLES.BOARDS,
  MY_HOSPITALS_APPOINTMENT: '병원 예약 내역',
  HEALTH_INFORMATION: '현재 상태',
  DELETE_ACCOUNT: '회원 탈퇴',
  NOTICES: '공지사항',
  ERROR: '오류',
  TERMS_SERVICE: '서비스 이용약관',
  TERMS_PRIVACY: '개인정보 처리방침',
  TERMS_SENSITIVE: '민감정보 수집 및 이용 동의',
  TERMS_MARKETING: '이벤트 및 마케팅 활용 동의',
  TERMS_PERSONAL: '개인정보 수집 및 이용 동의',
  TERMS_THIRD_PARTY: '개인정보 제3자 제공 동의',
  TERMS_IMPROVEMENT: '서비스 개발품질 향상을 위한 개인정보 수집 및 이용 동의',
  EVENTS: '이벤트',
  SERVICE: '서비스 소개',
  SEARCH: '검색',
  HELP: '고객센터',
  INSPECTION: '점검',
  PLUS_INTRO: '소개 | 플러스 리포트',
  PLUS_START: '리포트',
  PLUS_MAIN: '플러스 리포트',
  GLOSSARY: '암용어사전',
  REGIMENS: '항암 정보',
  COUNSELS: '플러스 상담',
}

/**
 * 메타 타이틀에 "루닛케어"를 붙인다.
 */
Object.entries(META_TITLES).forEach(([key, value]) => {
  if (key === 'MAIN') return
  META_TITLES[key] = `${value} - ${LUNIT_CARE}`
})

const META_DESCRIPTIONS: { [key: string]: string } = {
  ASSISTANT:
    '암 예방 및 환자 치료를 위한 맞춤형 식단과 건강한 식습관, 음식을 알려주는 AI 영양사',
  MAIN: DESCRIPTION.MAIN,
  CONTENTS: '치료 상태와 관심사를 고려한 암 정보 추천',
  BOARDS_MAIN: DESCRIPTION.BOARDS,
  BOARD_VIEW: DESCRIPTION.BOARDS,
  BOARD_CREATE: DESCRIPTION.BOARDS,
  BOARD_UPDATE: DESCRIPTION.BOARDS,
  BOARD_CREATE_COMPLETE: DESCRIPTION.BOARDS,
  BOARD_UPDATE_COMPLETE: DESCRIPTION.BOARDS,
  BOARD_ANSWER_PROCESS: DESCRIPTION.BOARDS,
  GUIDES_MAIN: DESCRIPTION.GUIDES,
  GUIDES_LIST: DESCRIPTION.GUIDES,
  GUIDE_VIEW: DESCRIPTION.GUIDES,
  HOSPITALS_MAIN: DESCRIPTION.HOSPITALS,
  HOSPITALS_SEARCH: DESCRIPTION.HOSPITALS,
  HOSPITALS_DETAIL: DESCRIPTION.HOSPITALS,
  MYCARE: '암 환자와 보호자를 위한 원스톱 토탈 케어 서비스',
  MYCARE_BOARDS: DESCRIPTION.BOARDS,
  MYCARE_BOARD_VIEW: DESCRIPTION.BOARDS,
  EVENTS: DESCRIPTION.MAIN,
  SERVICE: DESCRIPTION.MAIN,
  SEARCH: '검색 결과 - 암 정보는 루닛케어',
  PLUS_INTRO: '회복에 필요한 암 정보를 미리 확인해 보세요.',
  REGIMENS: '암 종류와 병기, 치료 시기에 따라 달라지는 적절한 항암제 찾기',
  HOSPITALS_APPOINTMENT:
    '암 의심 소견이나 진단 후 병원 선택에 어려움을 겪는 환자와 보호자를 위한 대형 병원 예약 대행 서비스. 진료 예약부터 상담까지 원스톱 지원',
  COUNSELS:
    '10년 이상 암 상담 전문 의료진의 1:1 관리와 건강 상태에 맞는 맞춤 정보 확인',
}

const DEFAULT_OG_IMAGE = `${S3_IMAGE_URL}/opengraph_image.png?v=3.0`
const PLUS_OG_IMAGE = `${S3_IMAGE_URL}/plus_opengraph_image.png`

export { META_TITLES, META_DESCRIPTIONS, DEFAULT_OG_IMAGE, PLUS_OG_IMAGE }
